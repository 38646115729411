<template>
  <div class="department">
    <div class="department_operation">
      <el-row justify="space">
        <el-col
          :xs="6"
          :sm="6"
          :md="6"
          :lg="12"
          :xl="12"
        >
          <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            @click="handleCreate()"
          >新建组织</el-button>
        </el-col>
        <el-col
          :xs="{span: 16, offset: 2}"
          :sm="{span: 12, offset: 6}"
          :md="{span: 12, offset: 6}"
          :lg="{span: 6, offset: 6}"
          :xl="{span: 6, offset: 6}"
        >
          <el-input
            v-model="search"
            placeholder="请输入关键字搜索"
            clearable
          />
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="records"
      row-key="code"
      header-cell-class-name="department_table-header"
      default-expand-all
      :tree-props="{
        children: 'children', hasChildren: 'hasChildren'
      }"
      v-loading="isFetching"
    >
      <el-table-column label="组织名称" prop="name"></el-table-column>
      <el-table-column label="组织代码" prop="code"></el-table-column>
      <el-table-column label="生效时间" prop="effectiveDate"></el-table-column>
      <el-table-column align="right">
        <template #header>
          
        </template>
        <template #default="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            icon="el-icon-plus"
            @click="handleCreate(scope.row)">新增</el-button>
          <el-button
            size="mini"
            plain
            icon="el-icon-edit"
            @click="handleEdit(scope.row)">修改</el-button>
          <el-popconfirm
            confirmButtonText='好的'
            cancelButtonText='不用了'
            title="确定删除这条记录吗？"
            @confirm="handleDelete(scope.row)"
          >
          <template #reference>
            <el-button
              size="mini"
              type="danger"
              plain
              icon="el-icon-delete"
            >删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <ModifyDepartment
      :visible="modifyDepartmentPopupVisible"
      :records="departments"
      :onSelect="handleSelect"
      :onSubmit="handleModifyDepartmentSubmit"
      :isEdit="isEdit"
      :defaultValues="currentDerpartment"
      :onClose="() => modifyDepartmentPopupVisible = false"
    />
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import * as api from '@constants/apis';
import ModifyDepartment from '../components/modifyDepartment';
import { filterDepartment } from '../utils';

export default {
  data() {
    return {
      search: '',
      modifyDepartmentPopupVisible: false,
      isEdit: false,
      currentDerpartment: {},
    }
  },
  components: {
    ModifyDepartment,
  },
  computed: {
    records() {
      return filterDepartment(this.search, this.$store.state.organization.departments);
    },
    isFetching() {
      return this.$store.state.organization.isFetchingDepartments
    }
  },
  mounted() {
    this.$store.dispatch('organization/getDepartments')
  },
  methods: {
    handleCreate(row) {
      this.modifyDepartmentPopupVisible = true;
      this.isEdit = false;
      if (row) {
        this.currentDerpartment = {
          parentCode: row.code,
          parentName: row.name,
        }
      }
      this.currentDerpartment = {}
    },
    async handleDelete(row) {
      console.log('delete', row)
      this.isDeleting = true;
      await this.$httpRequest.delete(`${api.ORGANIZATION}/${row.id}`);
      this.isDeleting = false;
    },
    handleEdit(row) {
      // TODO: find parent node and disable child node 
      this.isEdit = true;
      this.modifyDepartmentPopupVisible = true;
      this.currentDerpartment = {
        parentCode: row.code,
        parentName: row.name,
        id: row.id,
        code: row.code,
        name: row.name,
      }
    },
    async handleModifyDepartmentSubmit({ name, code, parentCode }) {
      try {
        const requestBody = {
          name, code,
          parentCode
        };
        const path = `${api.ORGANIZATION}/${this.isEdit ? this.currentDerpartment.id : ''}`;
        await this.$httpRequest[this.isEdit ? 'put' : 'post'](path, requestBody);
        this.isEdit = false;
        this.modifyDepartmentPopupVisible = false;
        this.currentDerpartment = {};
      } catch (err) {
        ElMessage.error(err.message)
      }
    },
  }
}
</script>

<style lang="scss">
.department {
  padding: $padding;
  border-radius: 0 $border-radius $border-radius $border-radius;
  @include background-color-content();
  &_operation {
    padding-bottom: $padding-xxl;
  }
  &_table-header {
  background-color: transparent !important;
}
}
</style>