<template>
  <!-- eslint-disable -->
  <el-dialog
    :title="title"
    v-model="visible"
    @closed="onClose"
  >
    <div class="modify-department">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="parentName" label="上级组织（此项为空则为一级组织）">
          <el-input
            v-model="form.parentName"
            placeholder="选择上级组织"
            clearable
            readonly
            @click="handleSelectDepartment"
          >
            <template #suffix>
              <el-button
                v-if="!!form.parentName"
                size="mini"
                icon="el-icon-close"
                circle
                @click="clearParent"
                style="transform: scale(0.8)"
                title="清除"
              ></el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="name" label="组织名称">
          <el-input
            v-model="form.name"
            placeholder="组织名称"
          />
        </el-form-item>
        <div class="modify-department_footer">
          <el-button
            @click="onClose"
          >
            取消
          </el-button>
          <el-button
            type="primary"
            @click="handleSubmit"
          >
            提交
          </el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
  <DepartmentsTree
    :onSelect="handleDepartmentSelect"
    :visible="departmentsTreePopupVisible"
    :onClose="() => departmentsTreePopupVisible = false"
  />
</template>

<script>
import DepartmentsTree from './departmentsTree';
export default {
  data() {
    return {
      loading: false,
      departmentsTreePopupVisible: false,
      form: {
        parentCode: '',
        parentName: '',
        name: '',
        code: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入组织名称',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  props: {
    onClose: Function,
    onSubmit: Function,
    onSelectDepartment: Function,
    defaultValues: Object,
    visible: Boolean,
    isEdit: Boolean,
  },
  components: {
    DepartmentsTree,
  },
  computed: {
    title() {
      return this.isEdit ? `修改组织信息 - ${this.defaultValues.parentName}` : '新建组织'
    }
  },
  watch: {
    defaultValues({
      parentCode,
      parentName,
      name,
      code,
    }) {
      this.form = {
        parentCode,
        parentName,
        name,
        code,
      }
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        const isFormValid = await this.$refs.form.validate();
        if (!isFormValid) { return; }

        await this.onSubmit(this.form);
        this.loading = false;
      } catch (err) {
        console.log('Error: Modify department', err);
      } finally {
        this.loading = false;
      }
      
    },
    clearParent() {
      this.form.parentCode = "";
      this.form.parentName = "";
    },
    handleDepartmentSelect({ code, name }) {
      this.form.parentCode = code;
      this.form.parentName = name;
      this.departmentsTreePopupVisible = false;
    },
    handleSelectDepartment() {
      console.log('handleSelectDepartment');
      this.departmentsTreePopupVisible = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.modify-department {
  &_footer {
    padding-top: $padding;
    display: flex;
    justify-content: flex-end;
  }
}
</style>